/* Dropdown.css */
.slide-enter {
  max-height: 0;
  opacity: 0;
}

.slide-enter-active {
  max-height: 1000px; 
  opacity: 1;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.slide-exit {
  max-height: 1000px; 
  opacity: 1;
}

.slide-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}
