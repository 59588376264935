.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
  }
  
  .modal-content-land {
    background: white;
    padding: 14px;
    border-radius: 8px;
    width: 90%;
    text-align: center;
    /* height: 254px; */
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .modal-content-land h3{
    justify-content: center;
    display: flex;
    margin-bottom: 6px;
  }

  .modal-buttons{
           /* position: absolute; */
    bottom: 18px;
    right: 34px;
    width: 54%;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    margin-left: 42%;
    margin-top: auto;
  }

  .heading_mob_num{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .heading_mob_num_1{
    font-size: 16px;
    font-weight: 400;
    justify-content: left !important;
    text-align: left;
    margin-bottom: 13px;
  }
  .heading_mob_num_2{
    font-size: 14px;
    justify-content: left !important;
  }

  .heading_otp_num{
    margin-top: 12px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .heading_otp_num_1{
    margin-bottom: 8px;
  }
  .heading_otp_num_2{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .heading_otp_num_3{
    font-weight: 600;
    font-size: 20px;
    color: #FFB800;
    margin-bottom: 10px;
  }
  
  .phone-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 16px;
  }
  
  .country-code-dropdown {
    width: 24%; /* Adjust width for the dropdown */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px; /* Rounded corner on the left */
    min-width: 80px;
  }
  
  .phone-number-input {
    width: 77%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
  }
  

  

  .otp-input-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
    /* margin-top: 9px; */
    margin-bottom: 16px;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    margin-right: 5px;
  }

  .otp-input-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
  }

  .resend-otp-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;
  }
  .resend-otp-cont h3{
    margin-bottom: 0;
    margin-right: 7px;
  }
  
  .resend-otp-cont button{
    font-size: 14px;
    font-weight: 600;
    color: #FFB800;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    border: 2px solid lightgray; /* Default gray border */
    border-radius: 8px;
    transition: border-color 0.3s ease;
    outline: none;
  }
  
  .otp-input:focus {
    border-color: #FFB800; /* Turns golden when focused */
  }
  
  .otp-input:not(:focus):not(:placeholder-shown) {
    border-color: rgb(168, 165, 159); /* Turns golden when a value is entered */
  }
  
  .otp-input:placeholder-shown {
    border-color: lightgray; /* Keeps gray border when no value is entered */
  }
  